// some colors

// edit bootstrap primary color
$primary: #04a9a8;

@import "~bootstrap/scss/bootstrap";

:root {
  --color_background: #F9F9F9;
  --brand_color_primary: #04a9a8;
  --brand_color_primary_hover: #059797;
  --brand_color_primary_lighter: #3dcece;
  --warning_information_background: rgb(214, 44, 44);
  --warning_information_background_border: rgb(116, 21, 21);
}


* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // make page overall bigger on bigger screens
  @media (min-width: 2000px) {
      zoom: 170%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
}

// make bootstrap button a lil bit more round
.btn, button {
  border-radius: 10px;
}
.btn[margin] .button[margin] {
  margin: 7px;
}
.btn-primary:hover {
  background-color: var(--brand_color_primary_hover);
  border: auto;
}